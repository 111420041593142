import React, { createContext, useReducer, useContext } from "react";

const OrderContext = createContext();

const orderReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ORDER": {
      if (state?.orders?.length >= 10) {
        state.orders = state.orders.filter(
          (_, index) => index < state.orders.length - 1
        );
      }
      return Object.assign({}, state, {
        orders: [action.payload, ...state.orders],
      });
    }
    case "INIT_ORDERS": {
      return { ...state, orders: action.payload };
    }
    case "ADD_ORDERS": {
      return { ...state, orders: [...state.orders, ...action.payload] };
    }
    case "UPDATE_ORDER":
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.id_order == action.payload.orderId) {
            order.status = action.payload.status;
            return order;
          }
          return order;
        }),
      };
    case "UPDATE_ETA_IN_ORDER":
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.id == action.payload.orderId) {
            return { ...order, eta_to_destination: action.payload.eta };
          }
          return order;
        }),
      };
    case "SHOW_ICON_ANIMATION_IN_ORDER":
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.id == action.payload.orderId) {
            return { ...order, showUpdatedLogIconAnimation: true };
          }
          return order;
        }),
      };
    case "UPDATE_SINGLE_ORDER": {
      let doFilter = false;
      if (action.payload.filterItOut) {
        doFilter = true;
      } else if (action.payload.filter) {
        if (
          action.payload.filter === "all" &&
          (action.payload.order.status === "completed" ||
            action.payload.order.status === "driver_canceled" ||
            action.payload.order.status === "client_canceled" ||
            action.payload.order.status === "assign_failed" ||
            action.payload.order.status === "timedout")
        ) {
          doFilter = true;
        } else if (
          action.payload.filter === "new" &&
          !(
            action.payload.order.status === "scheduled" ||
            action.payload.order.status === "new"
          )
        ) {
          doFilter = true;
        } else if (
          action.payload.filter === "scheduled" &&
          !(
            action.payload.order.status === "scheduled_accepted" ||
            action.payload.order.status === "accepted"
          )
        ) {
          doFilter = true;
        } else if (
          action.payload.filter === "started" ||
          action.payload.filter === "arrived" ||
          action.payload.filter === "in_progress"
        ) {
          if (action.payload.order.status !== action.payload.filter) {
            doFilter = true;
          }
        }
      }
      if (doFilter) {
        return {
          ...state,
          orders: state.orders.filter(
            (order) => order.id !== action.payload.order.id
          ),
        };
      } else {
        return {
          ...state,
          orders: state.orders.map((order) => {
            if (order.id === action.payload.order.id) {
              return { ...order, ...action.payload.order };
            }
            return order;
          }),
        };
      }
    }
    case "UPDATE_FILTER":
      return { ...state, orderFilter: action.payload };
    
    default:
      return state;
  }
};

export const OrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(orderReducer, { orders: [] });

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
