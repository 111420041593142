import React, { createContext, useReducer, useContext } from 'react'

const OrderFilterContext = createContext();

const orderFilterReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER':
      return { ...state, orderFilter: action.payload }
    case "UPDATE_TOPBAR_FILTER":
      return { ...state, topBarFilter: action.payload };
    default:
      return state
  }
}

export const OrderFilterProvider = ({ children }) => {
  const queryString = new URLSearchParams(typeof window !== 'undefined' ? location.search : "");
  const initState = {
    orderFilter: queryString.get("filter") ? queryString.get("filter") : "all"
  }
  const [state, dispatch] = useReducer(orderFilterReducer, initState)

  return (
    <OrderFilterContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderFilterContext.Provider>
  )
}

export const useOrderFilter = () => useContext(OrderFilterContext)