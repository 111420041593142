import React, { createContext, useState, useContext } from 'react'

const AuthContext = createContext({
  isAuthenticated: false, user: {}, login: () => { }, logout: () => { }, changeCurrentCompany: () => { }
});

export const AuthProvider = ({ children }) => {
  let localStorage;
  if (typeof window !== 'undefined') {
    localStorage = window.localStorage
  }

  let initialState = localStorage && JSON.parse(localStorage.getItem("userInfo")) ? JSON.parse(localStorage.getItem("userInfo")) : {}
  const [user, setUser] = useState(initialState)

  const login = ({ user, token }) => {
    setUser({ user: user, token: token })
    localStorage.setItem("userInfo", JSON.stringify({ user: user, token: token }))
  }

  const logout = () => {
    localStorage.removeItem("userInfo")
    setUser({})
  }

  const changeCurrentCompany = (companyId) => {
    setUser(prev => {
      return {
        ...prev,
        user: { ...prev.user, company_id: companyId }
      }
    })
  }

  const isAuthenticated = Object.keys(user).length > 0;

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, changeCurrentCompany }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)