import React, { createContext, useReducer, useContext } from 'react'

const SosContext = createContext();

const sosReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_SOS_CLIENTS':
      return Object.assign({}, state, {
        sosClients: [action.payload],
      })

    case 'ADD_SOS_CLIENT':
      return Object.assign({}, state, {
        sosClients: [...state.sosClients, action.payload],
      })

    case 'REMOVE_SOS_CLIENTS':
      return Object.assign({}, state, {
        sosClients: state.sosClients.filter(
          (sos) => sos.client_id !== action.payload.user_id
        ),
      })
    case 'UPDATE_SOS_CLIENT_LOCATION':
      return Object.assign({}, state, {
        sosClients: [
          ...state.sosClients.filter(
            (client) => client.client_id !== action.payload.client_id
          ),
          action.payload,
        ],
      })
    default:
      return state
  }
}

export const SosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sosReducer, {
    sosClients: [],
  })

  return (
    <SosContext.Provider value={{ state, dispatch }}>
      {children}
    </SosContext.Provider>
  )
}

export const useSos = () => useContext(SosContext)