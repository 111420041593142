import React, { createContext, useReducer, useContext } from 'react'

const DriverContext = createContext();

const driverReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ONLINE_DRIVERS':
      return Object.assign({}, state, {
        onlineDrivers: [...action.payload],
      })
    case 'ADD_ONLINE_DRIVER':
      return Object.assign({}, state, {
        onlineDrivers: [...state.onlineDrivers, action.payload],
      })

    case 'REMOVE_ONLINE_DRIVER':
      return Object.assign({}, state, {
        onlineDrivers: state.onlineDrivers.filter(
          (driver) => driver.id !== action.payload.id
        ),
      })
    case 'UPDATE_DRIVER_LOCATION':
      return Object.assign({}, state, {
        onlineDrivers: [
          ...state.onlineDrivers.map(
            (driver) => {
              if (driver.id === action.payload.id_driver) {
                return {
                  ...driver,
                  bearing: action.payload.bearing,
                  speed: action.payload.speed,
                  origin: {
                    location: {
                      lat: action.payload.latitude,
                      lng: action.payload.longitude,
                    }
                  }
                }
              } else {
                return driver
              }
            }
          )
        ],
      })
    case 'UPDATE_ETA_IN_DRIVER':
      return Object.assign({}, state, {
        onlineDrivers: [
          ...state.onlineDrivers.map(
            (driver) => {
              if (driver && driver.order && driver.order.id_order && driver.order.id_order === action.payload.orderId) {
                return {
                  ...driver,
                  eta_to_destination: action.payload.eta,
                }
              } else {
                return driver
              }
            }
          )
        ],
      })
    case 'ADD_SOS_DRIVERS':
      const onlineDrivers = state.onlineDrivers
      let sosDriverIndex = onlineDrivers.findIndex(element => element.id === action.payload.driver_id)

      let sosDriver = onlineDrivers[sosDriverIndex]
      sosDriver.isSos = true
      sosDriver.sosToken = action.payload.token
      onlineDrivers.splice(sosDriverIndex, 1, sosDriver)
      return { ...state, onlineDrivers }

    case 'REMOVE_SOS_DRIVERS':
      const allDrivers = state.onlineDrivers
      let currentSosIndex = allDrivers.findIndex(element => element.id === action.payload.user_id)

      let sosDriverToRemove = allDrivers[currentSosIndex]
      sosDriverToRemove.isSos = false
      sosDriverToRemove.sosToken = null
      allDrivers.splice(currentSosIndex, 1, sosDriverToRemove)
      return { ...state, onlineDrivers: allDrivers }

    default:
      return state
  }
}

export const DriverProvider = ({ children }) => {
  const [state, dispatch] = useReducer(driverReducer, {
    onlineDrivers: [],
  })

  return (
    <DriverContext.Provider value={{ state, dispatch }}>
      {children}
    </DriverContext.Provider>
  )
}

export const useDriver = () => useContext(DriverContext)