import React, { createContext, useState, useContext } from 'react'

const SocketStatusContext = createContext({ socketStatus : null, updateSocketStatus: () => {} });

export const SocketStatusProvider = ({ children }) => {

  const [socketStatus, setSocketStatus] = useState(null)

  const updateSocketStatus = (status) => {
    setSocketStatus(status)
  }

  return (
    <SocketStatusContext.Provider value={{ socketStatus, updateSocketStatus }}>
      {children}
    </SocketStatusContext.Provider>
  )
}

export const useSocketStatus = () => useContext(SocketStatusContext)