import React, { createContext, useReducer, useContext } from 'react'

const RoutesDirectionsContext = createContext();

const routesDirectionsReducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_ROUTE':
      return { route: action.payload.route, showRouteMap: true, orderInfo: action.payload.orderInfo }
    case 'HIDE_ROUTE_MAP':
      return { route: null, showRouteMap: false, orderInfo: null }
    default:
      return state
  }
}

export const RoutesDirectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(routesDirectionsReducer, {
    route: null,
    showRouteMap : false,
    orderInfo: null
  })

  return (
    <RoutesDirectionsContext.Provider value={{ state, dispatch }}>
      {children}
    </RoutesDirectionsContext.Provider>
  )
}

export const useRoutesDirections = () => useContext(RoutesDirectionsContext)