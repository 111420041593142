import React, { createContext, useReducer, useContext } from 'react'

const ChatContext = createContext();

const chatReducer = (state, action) => {
  switch (action.type) {
    case 'ROOM_SUCCESS':
      return { ...state, rooms: { data: action.payload } }
    case 'ROOM_LOAD_MORE_SUCCESS':
      return { ...state, rooms: { data: [...state.rooms.data, ...action.payload], activeRoom: state.rooms.activeRoom } }
    case 'ROOM_CLEAR':
      return {...state, rooms: { data: [], activeRoom: state.rooms.activeRoom }}
    case 'ROOM_ONLINE':
      return {
        ...state, rooms: {
          data: state.rooms.data.map(room => {
            return room.driver_id === action.payload ? { ...room, status: 'online' } : room
          })
        }
      }
    case 'ROOM_OFFLINE':
      return {
        ...state, rooms: {
          data: state.rooms.data.map(room => {
            return room.driver_id === action.payload ? { ...room, status: 'offline' } : room
          })
        }
      }
    case 'ROOM_CHANGE_ON_MESSAGE': {
      if (state.messages.data.length > 0 && state.messages.data[0].roomId === action.payload.roomId) {
        state.messages.data.push(action.payload)
      }
      let updatedRoomData = []
      if (state.rooms.data.length > 0) {
        updatedRoomData = [
          state.rooms.data.find(room => room.id == action.payload.roomId),
          ...state.rooms.data.filter(room => room.id != action.payload.roomId)
        ].map(room => {
          if (room.id == action.payload.roomId) {
            room.text = action.payload.text
            // room.unread = room.unread + 1
            return room
          }
          return room
        })
      }
      const latestState = {
        ...state,
        rooms: {
          data: updatedRoomData,
          activeRoom: state.rooms.activeRoom
        },
        messages: {
          data: state.messages.data
        }
      }

      // if (action.payload.text) {
      //   latestState.rooms.data[0].text = action.payload.text
      // }
      if (action.payload.roomId != state.rooms.activeRoom) {
        latestState.rooms.data[0].unread = latestState.rooms.data[0].unread + 1
      }
      if (action.payload.roomId == state.rooms.activeRoom) {
        latestState.rooms.data[0].unread = 0
      }

      return latestState
    }
    case 'SEND_NEW_MESSAGE':
      return {
        ...state,
        messages: { data: [...state.messages.data, action.payload] },
        rooms: {
          data: [
            state.rooms.data.find(room => room.id == action.payload.roomId),
            ...state.rooms.data.filter(room => room.id != action.payload.roomId)
          ].map(room => {
            if (room.id == action.payload.roomId) {
              room.text = action.payload.text
              return room
            }
            return room
          }),
          activeRoom: state.rooms.activeRoom
        }
      }
    case 'ROOM_MESSAGE_UNREAD':
      return {
        ...state,
        rooms: {
          data: state.rooms.data.map(room => {
            if (room.id == action.payload) {
              room.unread = 0
              return room
            }
            return room
          }),
          activeRoom: state.rooms.activeRoom
        }
      }
    case 'ROOM_ERROR':
      return { ...state, rooms: { error: action.error } }
    case 'MESSAGE_SUCCESS':
      return { ...state, messages: { data: action.payload } }
    case 'MESSAGE_LOAD_MORE':
      return { ...state, messages: { data: [...action.payload, ...state.messages.data] } }
    case 'MESSAGE_ERROR':
      return { ...state, messages: { error: action.error } }
    case 'SET_ACTIVE_ROOM':
      return {
        ...state,
        rooms: {
          data: state.rooms.data.map(room => {
            if (room.id == action.payload) {
              room.unread = 0
              return room
            }
            return room
          }),
          activeRoom: action.payload
        }
      }
    case 'SET_INACTIVE_ROOM':
      return {
        ...state,
        rooms: {
          data: state.rooms.data,
          activeRoom: ""
        },
        messages: {
          room_id: "",
          data: []
        },
      }
    case 'SET_TOTAL_UNREAD':
      return { ...state, totalUnread: action.payload }
    case 'NEW_MESSAGE_ADD_COUNT':
      return { ...state, totalUnread: state.totalUnread + 1, showAnimation: true }
    case 'REMOVE_TOTAL_UNREAD':
      return { ...state, totalUnread: state.totalUnread - action.payload }
    case 'ANIMATION_HIDE':
      return { ...state, showAnimation: false }
    default:
      return state
  }
}

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, {
    rooms: {
      data: [],
      activeRoom: "",
    },
    messages: {
      room_id: "",
      data: []
    },
    totalUnread: 0,
    showAnimation: false
  })

  return (
    <ChatContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatContext.Provider>
  )
}

export const useChat = () => useContext(ChatContext)